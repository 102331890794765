<template src="./index.html" />

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BCol, BRow, BForm, BFormGroup, BFormInput, BFormSelect, BPagination, BCard,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import { STATUSES } from '@/const/status'
import { getUser } from '@/auth/utils'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import FormSave from '@/views/admin/graduate-dissertation/FormSave'
import { Flag } from '@/const/flag'
import vSelect from 'vue-select'
export default {
  name: 'FormBuilding',
  components: {
    ValidationObserver,
    ValidationProvider,
    BButton,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    VueGoodTable,
    BPagination,
    FormSave,
    BCard,
    vSelect

  },
  data() {
    return {
      user: getUser(),
      loading: true,
      serverParams: {
        currentPage: 1,
        itemsPerPage: 10,
        status: Flag.ACTIVE,
        courseId: null,
        employeeId: null,
      },
      editMode: false,
      fields: [
        {
          field: 'name',
          label: 'Tên khóa luận tốt nghiệp ',
          sortable: true,
          thClass: 'text-center',
        },
        {
          field: 'courseName',
          label: 'Khóa học',
          sortable: false,
          thClass: 'text-center',
        },
        {
          field: 'status',
          label: 'Trạng thái',
          sortable: false,
        },
        {
          field: 'orderNo',
          label: 'Thứ tự',
          sortable: true,
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          field: 'action',
          label: '',
          tdClass: 'text-center',
          sortable: false,
        },
      ],
      dataForm: {},
    }
  },
  computed: {
    // calculate data from init data
    ...mapGetters({
      dataSources: 'graduationDissertation/dataSources',
      total: 'graduationDissertation/totalRows',
      courses: 'graduationDissertation/courses',
    }),
    creatable() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.GRADUATION_DISSERTATION)
    },
    updatable() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.GRADUATION_DISSERTATION)
    },
    deletable() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.GRADUATION_DISSERTATION)
    },
  },
  async created() {
    await Promise.all([
      this.getCourses({ currentPage: 1, itemsPerPage: 1000, organizationId: this.user.orgId }),
      this.getData(),
    ])
    this.optionsStatus = STATUSES
  },
  methods: {
    ...mapActions({
      getDataSources: 'graduationDissertation/getDataSources',
      saveData: 'graduationDissertation/saveDataSource',
      deleteData: 'graduationDissertation/deleteDataSource',
      getCourses: 'graduationDissertation/getCourses',
    }),
    isSystemAdmin,
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    async onPageChange(params) {
      this.updateParams({ currentPage: params.currentPage })
      await this.getData()
    },
    async onPerPageChange(params) {
      this.updateParams({ currentPage: 1, itemsPerPage: params.currentPerPage })
      await this.getData()
    },
    async onSortChange(param) {

    },
    async onColumnFilter(params) {
      const { columnFilters } = params
      this.updateParams(columnFilters)
      await this.getData()
    },
    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },

    async deleteItem(item) {
      this.$swal
        .fire({
          title: 'Xóa bản ghi này?',
          text: 'Bạn sẽ không thể khôi phục dữ liệu này sau khi xóa !',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
          cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        .then(async result => {
          if (result.isConfirmed) {
            this.loading = true
            try {
              const rs = await this.deleteData(item.id)
              if (rs.isSuccessful) {
                await this.getData()
                this.showToast('Đã xóa thành công!', 'CheckIcon', 'success', '')
              } else {
                this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'warning', '')
              }
            } catch (e) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `[${e.code}] ${e.message}`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            } finally {
              this.loading = false
            }
          }
        })
    },


    openModal() {
      this.dataForm = {}
      this.$refs['modal-form'].show()
    },

    editRow(data) {
      this.dataForm = data
      this.$refs['modal-form'].show()
    },

    async saveDataForm(data) {
      this.loading = true
      try {
        let dataSend = {
          name: data.name,
          orderNo: data.orderNo,
          status: data.status,
          courseId: data.courseId,
          employeeId: data.employeeId,
          description: data.description
        }
        if (data.id) {
          dataSend = { ...dataSend, ...{ id: data.id } }
        }
        const response = await this.saveData(dataSend)
        if (response.isSuccessful) {
          this.$bvModal.hide('modal-form')
          this.showToast('Cập nhật thành công!', 'CheckIcon', 'success', '')
          await this.getData()
        }
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'warning')
      } finally {
        this.loading = false
      }
    },

    getStatusName(id) {
      return STATUSES.find(status => status.value === id).label
    },

    async getData() {
      await this.getDataSources(this.serverParams)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
